/* You can add global styles to this file, and also import other style files */

.invoicing,
.invoicingForm {
  font-family: "acumin-pro";
  font-size: 18px;
}

.greenTitle {
  color: #00a443;
  font-size: 44px;
  font-weight: 500;
  line-height: 53px;
  letter-spacing: 0px;
  text-align: left;
}

.blueTitle {
  color: #000096;
  font-style: italic;
}

.subtitle {
  font-family: "acumin-pro-semi-condensed";
  color: #354a64;
  font-size: 20px;
  line-height: 26px;
}

.textInfo {
  color: #768496;
  font-size: 14px;
}

.asterisk {
  color: #fc4c55;
}

.warning {
  font-family: "acumin-pro";
  font-size: 16px;
  font-weight: 700;
}

.disclaimer {
  background-image: url("assets/img/ico-info.png");
  background-position: 13px 13px;
  background-repeat: no-repeat;
  background-color: rgba(195, 255, 185, 0.6);
  padding: 15px 15px 15px 40px;
  font-family: "acumin-pro-semi-condensed";
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0px;
  border-radius: 8px;
  color: #354a64;
}

.labelText {
  color: #354a64;
  font-size: 17px;
  line-height: 28px;
  font-weight: 600;
}
.container a {
  color: #00a443;
  text-decoration: none;
}
a:hover {
  color: #008a38;
}

input,
select {
  background-color: #f7f8f9 !important;
  padding: 18px !important;
  border: 1px solid #a6afbb !important;
  border-radius: 8px;
}
input:focus,
select:focus {
  border: 1px solid #a6afbb !important;
  box-shadow: none !important;
}
.form-check input {
  background-color: #ffffff !important;
  border: 2px solid #c1c1c1 !important;
  margin-right: 10px !important;
  padding: 14px !important;
}
.form-check input:checked {
  background-color: #00a443 !important;
}
input.numbersCard {
  border-right: none !important;
}
.input-group-addon {
  border: 1px solid #a6afbb !important;
  border-radius: 0 8px 8px 0;
}

.groupPass button {
  background-color: #f7f8f9;
  border: none;
  height: 60px;
  border-radius: 0 8px 8px 0px;
  padding-right: 20px;
}
.groupPass button:focus-visible {
  outline: none !important;
}

.amount,
.amount:focus {
  background: #f7f8f9;
}

.calendar,
.calendar:focus {
  background: #f7f8f9 !important;
  padding: 16px 18px !important;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: #f7f8f9 url(assets/img/calendar.png) no-repeat 100% 50% !important;
  width: 32px;
  height: 28px;
  padding: 0 !important;
  margin: 0 !important;
  cursor: pointer;
}

.greenButton {
  background-color: #00a443;
  padding: 24px 26px;
  justify-content: center;
  align-items: flex-start;
  color: #fff;
  border-radius: 8px;
  border: #00a443 1px solid;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  width: 240px;
}
.greenButton:hover,
.greenButton:focus,
.greenButton:focus-visible {
  background-color: #008a38;
  border: #008a38 1px solid !important;
}
.greenButton a {
  color: #fff;
  text-decoration: none;
  padding-right: 5px;
}
.greenButton.btSpinner {
  display: flex;
}
.greenButton.btSpinner span {
  margin-left: 5px;
}

.greenButton img,
.whiteButton img {
  padding: 3px 6px;
}
.whiteButton {
  background-color: #ffffff;
  padding: 24px 26px;
  justify-content: center;
  align-items: flex-start;
  color: #00a443;
  border-radius: 8px;
  border: #00a443 1px solid;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  width: 260px;
  margin: auto;
}
.whiteButton:hover {
  border: #008a38 1px solid;
  color: #008a38;
}
.buttonText {
  color: #00a443;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px; /* 100% */
  padding: 24px 0;
  display: inline-block;
  text-decoration: none;
}
.buttonText:hover {
  color: #008a38;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.responseOk,
.responseKo,
.initialState {
  background-color: #f1f1e9;
}

.responseOk .resultado,
.responseKo .resultado,
.initialState .resultado {
  min-height: 732px;
}
.greySubtitle {
  color: #354a64;
  text-align: center;
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: 41px;
  letter-spacing: 0.374px;
}

.responseData {
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 2px 12px 0px rgba(20, 20, 43, 0.08);
  padding: 48px 40px;
}
.responseText {
  color: #354a64;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 28px;
  font-family: acumin-pro-semi-condensed;
}
.responseValue {
  color: #354a64;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}
.responseAmount {
  color: #00a443;
  font-size: 76px;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
}

.responseAmount span {
  font-size: 24px;
}
.responseInfo {
  color: #768496;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
}
.responseAction {
  color: #354a64;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.35px;
}

.infoError {
  color: #fc4c55;
  font-size: 13px;
}
.invoicing .greenButton{
  width: auto;
}

@media(max-width: 768px) {
  .invoicing .btnFullW,
  .invoicingForm .btnFullW {
      width: 100%;
  }
  .responseOk .resultado, .responseKo .resultado, .initialState .resultado {
    min-height: 1000px;
}
}

@media(max-width: 1399px) {
  .labelText{
    font-size: 16px;
  }
  .imgInvo{
    width: 100%;
  }
  /*
  button:lang(en){
      min-width: 246px !important;
  }
  */
}
